import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/simple-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "/lesson-plans/"
      }}>{`»`}{` Back to Lesson Plans`}</a></p>
    <p>{`Dear Students,`}</p>
    <p><strong parentName="p"><em parentName="strong">{`[AAA---Short debrief or grading status]`}</em></strong></p>
    <p>{`This week we get to the skeleton and surface layers from Garret's text, which are likely familiar to you. But don't take them for granted; there are key differences in our mindset as we design for interactive designs that are different from working with traditional graphic design.`}</p>
    <p>{`Be sure to review the requirements for P2C due this week and stay on top of the work required. Note there is a brief additional reading in the deliverable instructions here as well.`}</p>
    <p>{`Best,
`}<strong parentName="p"><em parentName="strong">{`[BBB---Prof name]`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      